@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  padding: 0;
  margin: 40px 0 0 0;
  font-family: "Ubuntu Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

/* also have 
  font-weight: 400 / 700 ;
  font-style: normal / italic ;
*/
.title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* header inputs */

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .2);
  padding-right: 40px;
  height: 70px;
  border-radius: 5px;
  position: relative;
}
.search input {
  width: 500px;
  padding: 10px;
  text-align: center;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  color: rgba(0, 0, 0, .7);
}
.search input:active, .search input:focus {
  outline: none; 
  border: none;  
}
.search button {
  height: 50px;
  background: #7f8ff4;
  color: white;
  border: none;
  width: 120px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s ease-in;
}
.inputDate {
  width: 180px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #495057;
  background-color: #ffffff;
  transition: all 0.3s ease;
  margin-top: 10px;
}
.inputDate:focus {
  border-color: #007bff;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
  outline: none;
}
.search button:disabled {
  opacity: 0.5;
  background-color: grey;
}

/* components */

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 14px 0px;
}
.flw {
  flex-wrap: wrap;
  width: 600px;
  margin: 0 auto;
}
.index, .count {
  font-size: 40px;
  margin-bottom: 15px;
}
.indicators {
  margin-bottom: 20px;
  width: 300px;
}
.procent {
  font-size: 20px;
  font-style: italic ;
  opacity: 0.6;
}
.error {
  padding: 50px;
  width: 300px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .2);
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: 0.4s ease-in;
  animation: hide 4s;
}
.errorNone {
  animation: hide 1s;
}
.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #7f8ff4;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 50px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes hide {
  0% {
    right: 20px;
  }
  70% {
    right: 20px;
  }
  90% {
    right: -500px;
  }
  100% {
    display: none;
  }
}

.inputHint {
  position: absolute;
  top: -25px;
  left: 10px;
  font-size: 12px;
  color: #888;
  background-color: #f1f1f1;
  padding: 2px 5px;
  border-radius: 3px;
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.search:focus-within .inputHint {
  opacity: 1;
  transform: translateY(0);
}

.near-span {
  font-size: 20px;
}

/* pagination */

.pagination-button {
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
  background: #007bff;
  color: white;
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* consts */

.pr0 {
  padding-right: 0;
  align-items: center;
}
.pl0 {
  padding-left: 0;
}
.w450 {
  width: 450px;
}
.jcsp {
  justify-content: space-between;
}
.fz-medium {
  font-size: 27px;
}
.fz-small {
  font-size: 20px;
}
.jcc {
  justify-content: center;
}

.customPad {
  padding: 0;
}

@media screen and (max-width: 900px) {
  .container {
    width: 80%;
  }
  .wrapper {
    display: block;
    margin: 0 auto;
  }
  .indicators {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .pagination-container, .search input, .search {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
  .wrapper {
    display: block;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 20px;
    padding-left: 5px;
  }
  .pagination-container, .search input, .search {
    width: 90%;
  }
  .search {
    padding-right: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .w450 {
    width: auto;
  }
  .index {
    font-size: 40px;
  }
  .pagination-container {
    margin-bottom: 20px;
  }
}

.fz50 {
  font-size: 50px;
}